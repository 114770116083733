import React from "react"

import Layout from "../components/layout"
import SEOComponent from "../components/seo"

const ContactPage = () => (
  <Layout pageName="Contact">
    <SEOComponent  title="Contact" />
    <div className="columns m-0 pt-4">
      <div className="column">
        <p>I can be reached for questions, collaborations, and custom commissions at the following:</p>
        <p>Twitter: @pob3d</p>
        <p>Email: paul@pob3d.com</p>
      </div>
    </div>
  </Layout>
)

export default ContactPage